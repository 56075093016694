import React from 'react'
import Layout from '../components/layout/Layout'

const NotFoundPage = () => (
  <Layout title="Conteúdo não encontrado - HDScience">
    <h1 style={{ marginTop: 350, textAlign: 'center' }}>
      404 - Conteúdo não encontrado
    </h1>
    <p style={{ marginBottom: 300, textAlign: 'center' }}>
      Infelizmente você tentou acessar um conteúdo que não existe.
    </p>
  </Layout>
)

export default NotFoundPage
